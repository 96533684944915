@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;500&display=swap');

.logo-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.75em;
  margin-top: -4px;
  margin-left: 5px;
}

@media (min-width: 640px) {
  .logo-title {
    font-size: 2em;
  }
}