@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;500&display=swap');

.studio {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 5em;
  margin-top: -30px;
  letter-spacing: 1px;
  color: white;
  text-align: center;
}

.sub-text {
  font-family: 'Roboto', sans-serif;
  color: white;
  text-align: center;
  letter-spacing: 2px;
  margin-top: 7em;
}

@media (min-width: 640px) {
  .studio {
    justify-content: flex-start;
  }

  .sub-text {
    margin-top: 9.5em;
  }
}

@media (min-width: 1024px) {
  .studio {
    text-align: left;
    padding-top: 2rem;
    font-size: 7.5em;
  }

  .sub-text {
    margin-top: -2em;
    margin-left: 1em;
  }
}
