@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;500&display=swap');

.navTitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.25em;
  margin-top: -2px;
  letter-spacing: .75px;
  margin-right: 1.5em;
  display: none;
  color: rgb(202, 138, 4);
}

@media (min-width: 640px) {
  .navTitle {
    display: flex;
  }
}